import RiskChipCell from '@app/src/components/Table/Cells/RiskChipCell'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import { RiskStatus } from '@app/src/types/resourceExplorer'
import { BarChartOutlined, PublicOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const InfoListItem: React.FC<{ icon: React.ReactNode; primary: string; secondary: string }> = ({
  icon,
  primary,
  secondary,
}) => {
  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={({ palette }) => ({ bgcolor: palette.info.light, color: palette.common.black })}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="subtitle1">{primary}</Typography>} secondary={secondary} />
    </ListItem>
  )
}

const InfoGridItem: React.FC<{ leftColumn: React.ReactNode; rightColumn: string }> = ({ leftColumn, rightColumn }) => {
  return (
    <Grid container columnGap={2}>
      <Grid item xs={3}>
        {leftColumn}
      </Grid>
      <Grid item xs={7}>
        <Typography color="textSecondary">{rightColumn}</Typography>
      </Grid>
    </Grid>
  )
}

interface ProviderRiskInfoDialogProps {
  open: boolean
  closeDialog: () => void
}

const ProviderRiskInfoDialog: React.FC<ProviderRiskInfoDialogProps> = ({ open, closeDialog }) => {
  const { formatMessage } = useIntl()
  const providerType = useCurrentProviderTypeName()
  const responders = useCurrentProviderTypeName({ isPlural: true })

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <Stack p={4} spacing={3}>
        <Typography variant="h5" textAlign="center">
          {formatMessage({ id: 'schemas.providerRisk.infoDialog.title' }, { providerType })}
        </Typography>
        <InfoListItem
          icon={<BarChartOutlined />}
          primary={formatMessage({ id: 'schemas.providerRisk.infoDialog.collectData' })}
          secondary={formatMessage({ id: 'schemas.providerRisk.infoDialog.collectDataInfo' })}
        />
        <InfoListItem
          icon={<PublicOutlined />}
          primary={formatMessage({ id: 'schemas.providerRisk.infoDialog.countryRiskAlerts' })}
          secondary={formatMessage({ id: 'schemas.providerRisk.infoDialog.countryRiskAlertsInfo' })}
        />
        <Stack spacing={1}>
          <Typography variant="h6">
            {formatMessage({ id: 'schemas.providerRisk.infoDialog.transparencyMethodology' })}
          </Typography>
          <Typography variant="body1">
            {formatMessage({ id: 'schemas.providerRisk.infoDialog.transparencyMethodologyInfo' }, { responders })}
          </Typography>
        </Stack>
        <InfoGridItem
          leftColumn={<RiskChipCell risk={RiskStatus.High} disableCell />}
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.highRiskInfo' })}
        />
        <InfoGridItem
          leftColumn={<RiskChipCell risk={RiskStatus.Medium} disableCell />}
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.mediumRiskInfo' })}
        />
        <InfoGridItem
          leftColumn={<RiskChipCell risk={RiskStatus.Low} disableCell />}
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.lowRiskInfo' })}
        />
        <InfoGridItem
          leftColumn={
            <Typography variant="subtitle1">
              {formatMessage({ id: 'schemas.providerRisk.infoDialog.yourFlags' })}
            </Typography>
          }
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.yourFlagsInfo' })}
        />
        <InfoGridItem
          leftColumn={
            <Typography variant="subtitle2" color="textSecondary">
              {formatMessage({ id: 'schemas.providerRisk.infoDialog.noData' })}
            </Typography>
          }
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.noDataInfo' })}
        />
        <InfoGridItem
          leftColumn={
            <Typography variant="subtitle2" color="textSecondary">
              {formatMessage({ id: 'schemas.providerRisk.infoDialog.pending' })}
            </Typography>
          }
          rightColumn={formatMessage({ id: 'schemas.providerRisk.infoDialog.pendingInfo' })}
        />
        <Box textAlign="center">
          <Button variant="text" onClick={closeDialog}>
            {formatMessage({ id: 'general.close' })}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default ProviderRiskInfoDialog
